import Chart from 'chart.js'
import RangeSlider from './range-slider'

class TimeSelector {

  constructor(element) {
    this.parentElement = document.querySelector(element)
    this.chartElement = this.parentElement.querySelector('.time-chart')
    this.sliderElement = this.parentElement.querySelector('.slider')
    this.fromElement = this.parentElement.querySelector('#date_from_input')
    this.toElement = this.parentElement.querySelector('#date_to_input')
    this.initializeChart()
    this.initializeSlider()
  }

  initializeChart() {
    const options = {
      responsive: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [{
          display: true,
          gridLines: {
            display: false,
          },
        }],
        yAxes: [{
          display: false,
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
          },
        }],
      },
    }

    // Plugin definition
    Chart.pluginService.register({
      beforeUpdate: chartInstance => {
        chartInstance.data.datasets.forEach(dataset => {
          dataset.backgroundColor = chartInstance.data.labels.map(label => {
            if (typeof dataset.backgroundColor === "function") {
              return dataset.backgroundColor.call(this, label)
            }
            return dataset.backgroundColor
          });
        });
      }
    });

    let datas = JSON.parse(this.chartElement.dataset.data)
    datas.datasets[0].backgroundColor = this.barColor

    this.chartElement = new Chart(
      this.chartElement,
      {
        type: 'bar',
        data: datas,
        options: options,
      }
    )
  }

  initializeSlider() {
    let newRangeSlider = new RangeSlider(this.sliderElement)
    newRangeSlider.onChange = function(min, max) {
      this.fromElement.value = min
      this.toElement.value = max
    }.bind(this)

    newRangeSlider.didChanged = function(min, max) {
      this.chartElement.data.datasets[0].backgroundColor = this.barColor
      this.chartElement.update(0)
    }.bind(this)
  }

  barColor = (value) => {
    return value >= parseFloat(this.fromElement.value) && value <= parseFloat(this.toElement.value) ? 'orange' : 'white'
  }
}

export default TimeSelector
