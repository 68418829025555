import Choices from 'choices.js'
import LeafletMap from './leaflet-map'

class LocationSelector {

  constructor(container) {
    this._element = document.querySelector(container)

    this._miniMapSelector = '.mini-map'

    this._maxMap = new LeafletMap('max-map')
    document.
      getElementById('mapModal').
      addEventListener('show.bs.modal', this.resetMap.bind(this))

    this._locationSelector = '.location-selector'
    this.initializeLocationSelector()
  }

  handleMiniMapClick = (event) => {
    document.getElementById('mapModal').classList.toggle('show')

    this._maxMap.refreshMap()
  }

  initializeLocationSelector() {
    let select = this._element.querySelector(this._locationSelector)
    select.addEventListener('change', (e) => {
      e.currentTarget.closest('form').submit()
    })

    new Choices(select).setChoices(async () => {
      try {
        const items = await fetch('/locations.json?q=""')
        return items.json()
      } catch (err) {
        console.error(err)
      }
    }, 'value', 'label', true)
  }

  resetMap() {
    this._maxMap.refreshMap()
  }
}

export default LocationSelector
