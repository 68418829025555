document.addEventListener('turbolinks:load', function () {
  const sidebars = document.querySelectorAll('.sidebar')
  window.addEventListener('resize', () => {
    if (window.innerWidth < 850) {
      sidebars.forEach(element => element.classList.add('collapsed'))
      return
    }

    sidebars.forEach(element => element.classList.remove('collapsed'))
  })
})