// Yarn managed JS
require('@rails/ujs').start()
// require('turbolinks').start()
require('channels')

// local JS
require('../components/global_tools')
require('../components/modal-meta')
require('../components/concept-meta')
require('../components/sticky-header')
require('../components/progress-bar')
require('../components/scrolltopbutton')
require('../components/side-panel')