import Choices from 'choices.js'

class ConceptSelector {

  constructor(container) {
    this._select = document.querySelector(container)
    this.initialize()
  }

  initialize() {
    let selector = this._select.querySelector('.concept-selector')
    selector.addEventListener('change', (e) => {
      e.currentTarget.closest('form').submit()
    })

    new Choices(selector).setChoices(async () => {
      try {
        const items = await fetch('/concepts.json')
        return items.json()
      } catch (err) {
        console.error(err)
      }
    }, 'value', 'label', true)
  }

}

export default ConceptSelector
