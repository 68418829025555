// THIS IS THE RANGE SLIDER LOGIC DO NOT CHANGE !!

class RangeSlider {

  constructor(element) {
    this.slider = element

    // retrieve touch button
    this.touchLeft = this.slider.querySelector('.slider-touch-left')
    this.touchRight = this.slider.querySelector('.slider-touch-right')
    this.lineSpan = this.slider.querySelector('.slider-line span')

    // get some properties
    this.min = parseFloat(this.slider.dataset.min)
    this.max = parseFloat(this.slider.dataset.max)

    // retrieve default values
    this.defaultMinValue = parseFloat(this.slider.dataset.minValue || this.min)
    this.defaultMaxValue = parseFloat(this.slider.dataset.maxValue || this.max)

    // check values are correct
    if(this.defaultMinValue < this.min) this.defaultMinValue = this.min
    if(this.defaultMaxValue > this.max) this.defaultMaxValue = this.max
    if(this.defaultMinValue > this.defaultMaxValue) this.defaultMinValue = this.defaultMaxValue

    this.reset()

    this.maxX = this.slider.offsetWidth - this.touchRight.offsetWidth
    this.step = Math.abs(parseFloat(this.slider.dataset.step || '1'))
    this.normalizeFact = 26

    this.selectedTouch = null
    this.initialValue = this.lineSpan.offsetWidth - this.normalizeFact

    // set defualt values
    this.setMinValue(this.defaultMinValue)
    this.setMaxValue(this.defaultMaxValue)

    this.touchLeft.addEventListener('mousedown', this.onStart.bind(this))
    this.touchRight.addEventListener('mousedown', this.onStart.bind(this))
    this.touchLeft.addEventListener('touchstart', this.onStart.bind(this))
    this.touchRight.addEventListener('touchstart', this.onStart.bind(this))
  }

  reset() {
    this.touchLeft.style.left = '0px'
    this.touchRight.style.left = (this.slider.offsetWidth - this.touchLeft.offsetWidth) + 'px'
    this.lineSpan.style.marginLeft = '0px'
    this.lineSpan.style.width = (this.slider.offsetWidth - this.touchLeft.offsetWidth) + 'px'
    this.startX = 0
    this.x = 0
  }

  setMinValue(minValue) {
    const ratio = ((minValue - this.min) / (this.max - this.min))
    this.touchLeft.style.left = Math.ceil(ratio * (this.slider.offsetWidth - (this.touchLeft.offsetWidth + this.normalizeFact))) + 'px'
    this.lineSpan.style.marginLeft = this.touchLeft.offsetLeft + 'px'
    this.lineSpan.style.width = (this.touchRight.offsetLeft - this.touchLeft.offsetLeft) + 'px'
    this.slider.dataset.minValue = minValue
  }
  
  setMaxValue(maxValue) {
    const ratio = ((maxValue - this.min) / (this.max - this.min))
    this.touchRight.style.left = Math.ceil(ratio * (this.slider.offsetWidth - (this.touchLeft.offsetWidth + this.normalizeFact)) + this.normalizeFact) + 'px'
    this.lineSpan.style.marginLeft = this.touchLeft.offsetLeft + 'px'
    this.lineSpan.style.width = (this.touchRight.offsetLeft - this.touchLeft.offsetLeft) + 'px'
    this.slider.dataset.maxValue = maxValue
  }

  onStart(event) {
    event.preventDefault();
    let eventTouch = event;

    if (event.touches) eventTouch = event.touches[0]
    this.x = event.currentTarget === this.touchLeft ? this.touchLeft.offsetLeft : this.touchRight.offsetLeft

    this.startX = eventTouch.pageX - this.x
    this.selectedTouch = event.currentTarget
    this.slider.addEventListener('mousemove', this.onMove.bind(this))
    this.slider.addEventListener('mouseup', this.onStop.bind(this))
    this.slider.addEventListener('touchmove', this.onMove.bind(this))
    this.slider.addEventListener('touchend', this.onStop.bind(this))
  }

  onMove(event) {
    let eventTouch = event;

    if (event.touches) eventTouch = event.touches[0]

    // console.log(this.selectedTouch)
    // console.log(this.touchLeft)

    this.x = eventTouch.pageX - this.startX
    if (this.selectedTouch === this.touchLeft) {
      if(this.x > (this.touchRight.offsetLeft - this.selectedTouch.offsetWidth + 10))
      {
        this.x = (this.touchRight.offsetLeft - this.selectedTouch.offsetWidth + 10)
      }
      else if(this.x < 0)
      {
        this.x = 0
      }
      this.selectedTouch.style.left = this.x + 'px'
    }
    else if (this.selectedTouch === this.touchRight) {
      if(this.x < (this.touchLeft.offsetLeft + this.touchLeft.offsetWidth - 10))
      {
        this.x = (this.touchLeft.offsetLeft + this.touchLeft.offsetWidth - 10)
      }
      else if(this.x > this.maxX)
      {
        this.x = this.maxX
      }
      this.selectedTouch.style.left = this.x + 'px'
    }

    // update line span
    this.lineSpan.style.marginLeft = this.touchLeft.offsetLeft + 'px'
    this.lineSpan.style.width = (this.touchRight.offsetLeft - this.touchLeft.offsetLeft) + 'px'

    // write new value
    this.calculateValue()

    if(this.onChange) {
      this.onChange(this.slider.dataset.minValue, this.slider.dataset.maxValue)
    }
  }

  onStop(event) {
    this.slider.removeEventListener('mousemove', this.onMove)
    this.slider.removeEventListener('mouseup', this.onStop)
    this.slider.removeEventListener('touchmove', this.onMove)
    this.slider.removeEventListener('touchend', this.onStop)

    this.selectedTouch = null

    // write new value
    this.calculateValue()

    // call did changed
    if(this.didChanged) {
      this.didChanged(this.slider.dataset.minValue, this.slider.dataset.maxValue)
    }
  }

  onStop(event) {
    this.slider.removeEventListener('mousemove', this.onMove)
    this.slider.removeEventListener('mouseup', this.onStop)
    this.slider.removeEventListener('touchmove', this.onMove)
    this.slider.removeEventListener('touchend', this.onStop)

    this.selectedTouch = null;

    // write new value
    this.calculateValue();
    
    // call did changed
    if(this.didChanged) {
      this.didChanged(this.slider.dataset.minValue, this.slider.dataset.maxValue)
    }
  }

  calculateValue() {
    let newValue = (this.lineSpan.offsetWidth - this.normalizeFact) / this.initialValue
    let minValue = this.lineSpan.offsetLeft / this.initialValue
    let maxValue = minValue + newValue

    minValue = minValue * (this.max - this.min) + this.min
    maxValue = maxValue * (this.max - this.min) + this.min

    if (this.step !== 0.0) {
      let multi = Math.floor((minValue / this.step))
      minValue = this.step * multi
      
      multi = Math.floor((maxValue / this.step))
      maxValue = this.step * multi
    }

    this.slider.dataset.minValue = minValue
    this.slider.dataset.maxValue = maxValue
  }
}

export default RangeSlider

// -------------------
// How to use? 
// var newRangeSlider = new ZBRangeSlider('my-slider');

// newRangeSlider.onChange = function(min, max)
// {
//   console.log(min, max, this);
//   document.getElementById('result').innerHTML = 'Min: ' + min + ' Max: ' + max;
// }

// newRangeSlider.didChanged = function(min, max)
// {
//   console.log(min,max, this);
//   document.getElementById('result').innerHTML = 'Min: ' + min + ' Max: ' + max;
// }

// call reset if needed
// newRangeSlider.reset();