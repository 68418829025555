// show / hide collection's article
document.addEventListener('turbolinks:load', function() {
  const element = document.querySelector('.collection-viewer')
  element && element.addEventListener('click', function(event) {
    event.preventDefault()
    document.querySelector('.collection-articles').classList.toggle('open')
  })
})

// show / hide author's article
document.addEventListener('turbolinks:load', function() {
  const element = document.querySelector('.author-viewer')
  element && element.addEventListener('click', function(event) {
    event.preventDefault()
    document
      .getElementById('author-' + event.currentTarget.dataset.authorId)
      .classList
      .toggle('open')
  })
})