import Choices from 'choices.js'

document.addEventListener('turbolinks:load', function() {
  const searches = document.querySelectorAll('select.index-search')
  for(const search of searches) {
    new Choices(search)
    search.addEventListener('change', () => {
      let params = new URLSearchParams(window.location.search)
      params.set(search.name, search.value)
      window.location.search = params
    })
  }
})