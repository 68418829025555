document.addEventListener('turbolinks:load', function() {
 const element = document.querySelector('.main-container')
 element && element.addEventListener(
    'scroll',
    (e) => {
      const scrollTop = event.currentTarget.scrollTop
      const scrollBottom = event.currentTarget.scrollHeight - document.documentElement.clientHeight
      const scrollPercent = scrollTop / scrollBottom * 100 + "%"

      document
              .getElementById('_progress')
              .style.setProperty('--scroll', scrollPercent)
    },
    { passive: true }
  )
})
