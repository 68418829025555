import consumer from "./consumer"

document.addEventListener('turbolinks:load', function() {
  consumer.subscriptions.create(
    { channel: "SuggestionsChannel" },
    {
      connected() {
        for(const element of document.querySelectorAll('.suggestion_list')) {
          fetch(element.dataset.suggestionUrl, {
            headers: { "Content-Type": "application/json; charset=utf-8" },
            method: 'GET'
          })
        }
      },

      received(data) {
        const container = document.querySelector('#' + data.suggestion_engine_identifier + '_suggestion_list')

        container.querySelector('.suggestions-preloader').classList.add('d-none')
        container.querySelector('.suggestions-container').innerHTML = data.suggestions

        this.handleBrowseButtons(container)
      },

      handleBrowseButtons(container) {
        container
          .querySelector('.browse-suggestions-down')
          .addEventListener('click', (event) => {
            container
              .querySelectorAll('.suggestion-desc:not(.d-none)+.suggestion-desc.d-none')
              .forEach(element => element.classList.remove('d-none'))
            })
      },
    },
  )
})
