import ConceptSelector from './concept-selector'
import LocationSelector from './location-selector'
import TimeSelector from './time-selector'
import IndexSelector from './index-selector'
import WelcomePopup from './welcome-popup'
import { Tooltip } from 'bootstrap'

window.addEventListener('load', (event) => {
  document.dispatchEvent(new Event('turbolinks:load'))
})

// bootstrap stuff
document.addEventListener('turbolinks:load', function() {
  let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })
})

// manage location
document.addEventListener('turbolinks:load', function() {
  new LocationSelector('.search-filter-item.location')
})

// manage concept
document.addEventListener('turbolinks:load', function() {
  new ConceptSelector('.search-filter-item.thesau')
})

// change font sizes
document.addEventListener('turbolinks:load', function() {
  for(let element of document.querySelectorAll('.font-modifier')) {
    element.addEventListener('click', function(event) {
      event.preventDefault()
      for(let article of document.querySelectorAll('.tei-body, .tei-front, .notes, .tei-back')) {
        article.style.fontSize = (parseFloat(article.style.fontSize) || 14) +
          parseFloat(event.currentTarget.dataset.size) +
          'px'
      }
    })
  }
})

document.addEventListener('turbolinks:load', function() {
  // show/hide blocks
  for(let element of document.querySelectorAll('.search-filter-item-top')) {
    element.addEventListener('click', function(event) {
      event.preventDefault()
      for(const children of event.target.parentNode.children) {
        children.classList.toggle('hide')
      }
      const classList = event.target.classList
      classList.toggle('hide')
      classList.toggle('hideup')
    })
  }
})

document.addEventListener('turbolinks:load', function() {
  // show/hide sidebars
  for(let element of document.querySelectorAll('.btn-toggle-sidebar')) {
    element.addEventListener('click', function(event) {
      event.preventDefault()
      event.target.closest('.sidebar').classList.toggle('collapsed')
    })
  }

  // manage time
  new TimeSelector('.time-filter')
})

// manage concept
document.addEventListener('turbolinks:load', function() {
  let breadcrumb = document.querySelector('.breadcrumbs ul')
  if(!breadcrumb) return

  breadcrumb.scrollLeft = breadcrumb.scrollWidth
})

