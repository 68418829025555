import L from 'leaflet'
require('leaflet.markercluster')

class LeafletMap {

  constructor(element) {
    this._selector = element
    this.extractOptions()
    this.initMap()
    this.addMarkerHandler()
  }

  extractOptions() {
    const opts = document
      .getElementById(this._selector)
      .dataset

    this._locationsUrl = opts.locations
    this._articleIcon = L.icon({
      iconUrl: opts.articleIconUrl,
      iconSize: [38, 61],
      iconAnchor: [19, 61],
      popupAnchor: [0, -68]
    })

    this._relativeIcon = L.icon({
      iconUrl: opts.relativeIconUrl,
      iconSize: [38, 61],
      iconAnchor: [19, 61],
      popupAnchor: [0, -64]
    })
  }

  initMap() {
    this._map = L.map(
      this._selector,
      { tap: L.Browser.safari && L.Browser.mobile }
    ).setView([51.505, -0.09], 1)

    L.tileLayer('https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.png', {
        attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> — Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
        maxZoom: 16,
        minZoom: 1,
        label: 'Watercolor',
      }
    ).addTo(this._map)

    // this._control = L.control
    //   .layers([], [], { hideSingleBase: true })
    //   .addTo(this._map)

    fetch(this._locationsUrl,
          { headers: { "Content-Type": "application/json; charset=utf-8" } })
      .then(response => response.json())
      .then(response => { this.addArticleLocations(response, this._articleIcon, 'Article en cours') })
  }

  refreshMap() {
    setTimeout(function() { this._map.invalidateSize() }.bind(this), 500)
  }

  addArticleLocations(response, icon, text) {
    let markers = L.markerClusterGroup()

    for (const location of response) {
      let marker = L.marker(
        new L.LatLng(location.lat, location.lng),
        { title: location.label_fr, icon: icon },
      )
      marker.bindPopup(
        '<p>' + location.label_fr + '<br />' +
        '<a class="popupLocation" href="' + this.urlWithCurrentLocation(location.id) + '"' +
        ' data-lattitude=' + location.lat +
        ' data-longitude=' + location.lng +
        ' data-title=' + location.label_fr +
        '>Orienter la recherche</a>' +
        '</p>',
        {
          'maxWidth': '500',
          'className' : 'custom'
        }
      ).on('popupopen', (event) => {
        document
          .querySelector('#' + this._selector + ' .leaflet-popup .popupLocation')
          .addEventListener('click', this.popupClickHandler)
        // console.log(event.popup.getContent())
        // console.log(event.popup.getContent().querySelector('.popupLocation'))
        // this.popupClickHandler(location.label_fr, location.lat, location.lng)
      });
      markers.addLayer(marker)
    }

    if (icon == this._articleIcon) {
      markers.addTo(this._map)
    } else {
      this._control.addOverlay(markers, text)
    }
  }

  addMarkerHandler() {
    document.addEventListener(
      'map.addMarker',
      this.addRelativeMarkers,
      false
    );
  }

  setupPopupLinkEvent() {
    const links = document.querySelectorAll('#' + this._selector + ' .popupLocation')

    for (link of links) {
      link.removeEventListener('click', this.popupClickHandler, false)
      link.addEventListener('click', this.popupClickHandler, false)
    }
  }

  popupClickHandler = (event) => {
    event.preventDefault
  }

  addRelativeMarkers = (event) => {
    fetch('/articles/' + event.detail.articleId + '/locations.json',
          { headers: { "Content-Type": "application/json; charset=utf-8" } })
      .then(response => { console.log(response); return response.json() })
      .then(response => {
        this.addArticleLocations(response, this._relativeIcon, event.detail.title)
      })
  }

  urlWithCurrentLocation(location_id) {
    let url = new URL(window.location)
    let params = new URLSearchParams(url.search)
    params.append('locations[]', location_id)
    url.search = params
    return url.toString()
  }
}

export default LeafletMap
