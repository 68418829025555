import { Modal } from 'bootstrap'

document.addEventListener('turbolinks:load', function() {
  const popup = document.getElementById('welcome-modal')
  const modal = popup && new Modal(popup)
  if (!modal) return

  modal.show()
  for(const link of popup.querySelectorAll('.nav-link')) {
    link.addEventListener('click', function(event) {
      event.preventDefault()
  
      for(let textes of popup.querySelectorAll('.text')) {
        textes.classList.add('d-none')
      }

      console.log('.text.' + link.dataset.language)
      console.log(popup.querySelector('.text.' + link.dataset.language))
      popup
        .querySelector('.text.' + link.dataset.language)
        .classList.remove('d-none')
    })
  }
})
